import axios from "axios";
import { Notify, Dialog } from "vant";

// import store from '../store'
// 创建axios实例
let baseUrlConfig = {
  development: "https://meetingassurancemonitoring.apps.k8sits.sustech.edu.cn/",
  test: "https://meetingassurancemonitoring.apps.k8sits.sustech.edu.cn/",
  production: "https://hybzjk.its.sustech.edu.cn"
};
let ultimateUrl = baseUrlConfig[process.env.NODE_ENV];
console.log(process.env.NODE_ENV, "ultimateUrl", ultimateUrl);
const service = axios.create({
  baseURL: ultimateUrl, // api 的 base_url
  timeout: 500000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    let token = window.sessionStorage.getItem("token");
    if (token) {
      config.headers["token"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] =
      "X-Requested-With,Content-Type";
    config.headers["Access-Control-Allow-Methods"] =
      "PUT,POST,GET,DELETE,OPTIONS";
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const code = response.data.code;
    if (code !== 10000 && code !== 35000) {
      if (code === 500) {
        // Notify({ type: "danger", message: "something went wrong" });
        if (response.data.message === "权限不足") {
          return response.data;
        }
        Dialog.alert({
          message: "登录过期，请重新登录"
        }).then(() => {
          // on close
          console.log(location.host);
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("sid");
          window.location.href = "https://cas-test.sustech.edu.cn/cas/logout";
        });
      } else if (code === 3500) {
        return response.data;
      }
      return Promise.reject(response.data);
    } else {
      return response.data;
    }
  },
  error => {
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    console.log(error, "error");
    Notify({ type: "danger", message: "error.message" });
    return Promise.reject(error);
  }
);

export default service;
